import React, { Fragment, useEffect, useState } from "react"

import { useLocation } from '@reach/router'
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies"
import { Link, FormattedMessage, useIntl } from 'gatsby-plugin-intl'

import cx from 'classnames'

import {
  cookiemonster,
  cookiemonster_cookie_list,
  cookiemonster_buttons_accept,
  cookiemonster_buttons_choose,

} from '../layouts/styles.module.css'

import Popover from './popover'
import LangSelector from './lang-selector'


interface Cookie {
  name: string,
  default: boolean,

  editable: boolean,
  title: string,
  text: string,
}

interface Props {
  cookieDays?: number,
}


const makeExpires = (days: number) => {
  const date = new Date()
  date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
  return "; expires=" + date.toUTCString()
}


interface CookiesChecklistProps {
  cookieList: Array<Cookie>
}

const CookiesChecklist = ({ cookieList }: CookiesChecklistProps) => (
  <div className={cx(cookiemonster_cookie_list)}>
    {cookieList.map(c => (
      <div key={c.name}>
        <input
          type="checkbox"
          defaultChecked={c.default}
          disabled={!c.editable}
          value={c.name}
          id={c.name}
        />
        <label htmlFor={c.name}>{c.title}</label>
        <p>{c.text}</p>
      </div>
    ))}
  </div>
)



const CookieNotice = (props: Props) => {

  const { cookieDays = 1 } = props

  const { messages } = useIntl()

  const cookieList: Array<Cookie> = [
    {
      name: 'necessary',
      editable: false,
      default: true,
      title: messages['cookiemonster.list.necessary.title'] as string,
      text: messages['cookiemonster.list.necessary.text'] as string,
    },
    {
      name: 'gdpr-google-analytics',
      editable: true,
      default: true,
      title: messages['cookiemonster.list.ga.title'] as string,
      text: messages['cookiemonster.list.ga.text'] as string,
    }
  ]


  const [displayCookieList, setDisplayCookieList] = useState(false)
  const [displayCookieNotice, setDisplayCookieNotice] = useState(false)

  const expires = makeExpires(cookieDays)

  const location = useLocation()

  const acceptCookie = () => {
    cookieList.forEach(c => document.cookie = c.name + "=true" + expires + "; path=/")
    initializeAndTrack(location)
    setDisplayCookieNotice(false)
  }

  /*
   * const declineCookie = () => {
    cookieList.forEach(c => {
      if (c.editable)
        return document.cookie = c.name + "=false" + expires + "; path=/"
      else
        return document.cookie = c.name + "=" + c.default + expires + "; path=/"
    })
    initializeAndTrack(location)
    setDisplayCookieNotice(false)
  }
  */

  const validatePreferences: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()

    cookieList.forEach(c => {
      if ((document.getElementById(c.name) as HTMLInputElement).checked)
        document.cookie = c.name + "=true" + expires + "; path=/"
      else
        document.cookie = c.name + "=false" + expires + "; path=/"

      initializeAndTrack(window.location)
      setDisplayCookieNotice(false)
    })
  }


  const cookieExist = (name: string) => {
    const dc = document.cookie
    const prefix = name + "="
    let begin = dc.indexOf("; " + prefix)
    if (begin === -1) {
      begin = dc.indexOf(prefix)
      if (begin !== 0) return null
    }

    begin += 2
    let end = document.cookie.indexOf(";", begin)
    if (end !== -1) {
      end = dc.length
    }
    return decodeURI(dc.substring(begin + prefix.length, end))
  }


  useEffect(() => {
    cookieList.forEach(c => { if (!cookieExist(c.name)) return setDisplayCookieNotice(true) })
  }, [cookieList])


  if (!displayCookieNotice) return null

  return (
    <Popover banner>
      <div className={cx(cookiemonster)}>
        <LangSelector text />
        <form onSubmit={validatePreferences}>
          {!displayCookieList && (
            <div>
              <h1><FormattedMessage id='cookiemonster.title' /></h1>
              <p><FormattedMessage id='cookiemonster.text' /></p>
            </div>
          )}

          {displayCookieList && <CookiesChecklist cookieList={cookieList} />}

        </form>



        <ul>

          <li>
            <button className={cx(cookiemonster_buttons_accept)} onClick={acceptCookie}>
              <FormattedMessage id='cookiemonster.buttons.continue' />
            </button>
          </li>
          <li>
            <button className={cx(cookiemonster_buttons_choose)} onClick={() => setDisplayCookieList(true)}>
              <FormattedMessage id='cookiemonster.buttons.choice' />
            </button>
          </li>
          <li><Link to='/privacy-policy'>
            <FormattedMessage id='common.pages.privacy-policy' />
          </Link></li>

          <li><Link to='/terms-and-conditions'>
            <FormattedMessage id='common.pages.terms-and-conditions' />
          </Link></li>

        </ul>
      </div>
    </Popover>
  )
}

export default CookieNotice
