import React, {
  useContext,
  createContext,
  useEffect,
  useState,
} from 'react'

import cx from 'classnames'

import {
  popover_container,
  popover_banner,
} from '../layouts/styles.module.css'


interface PopoverContext {
  count: number,
  increment: () => void,
  decrement: () => void,
}

const popoverContext = createContext<PopoverContext>({
  count: 0,
  increment: () => { },
  decrement: () => { },
})

export const usePopoverIsOpen = () => useContext(popoverContext).count !== 0;


interface PopoverContextProviderProps {
  children: React.ReactNode,
}

export const PopoverContextProvider = ({ children }: PopoverContextProviderProps) => {

  const [count, setCount] = useState(0);

  const increment = () => setCount((prev: number) => prev + 1);
  const decrement = () => setCount((prev: number) => prev - 1);

  return (
    <popoverContext.Provider value={{ count, increment, decrement }}>
      {children}
    </popoverContext.Provider>
  );
}

interface Props {
  children: React.ReactNode
  className?: any
  banner?: boolean
}

const Popover = ({ children, className, banner }: Props) => {
  const { increment, decrement } = useContext(popoverContext)

  useEffect(() => {
    increment();
    return decrement;
  }, [increment, decrement]);

  return (
    <div className={cx(popover_container, banner && popover_banner, className)}>
      {children}
    </div>
  )
}

export default Popover;
