import React, {
  useState,
  useMemo,
  useContext,
  useCallback,
  createContext,
} from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { IntlContextConsumer } from 'gatsby-plugin-intl'
import { Link, FormattedMessage } from 'gatsby-plugin-intl'

import cx from 'classnames'

import {
  nav_section,
  nav_open,
  nav_controls,
  nav_logo_section,
  active_nav_link,
  mobile_only,
  call_now,
} from '../layouts/styles.module.css'

import LangSelector from '../components/lang-selector'
import ContactDetails from '../components/contact-details'

const logo = '../images/kemene_logo_v3.png'

type Props = {}

type MenuState = boolean

interface NavContext {
  hideLogo: () => void,
  showLogo: () => void,
  hide: boolean,
}

const navContext = createContext<NavContext>({
  hideLogo: () => { },
  showLogo: () => { },
  hide: false,
})


interface NavContextProviderProps {
  children: React.ReactNode,
}

export const NavContextProvider = ({ children }: NavContextProviderProps) => {

  const [hide, setHide] = useState(false);

  const value = useMemo(() => ({
    hideLogo: () => setHide(true),
    showLogo: () => setHide(false),
    hide: hide
  }), [setHide, hide])

  return (
    <navContext.Provider value={value}>
      {children}
    </navContext.Provider>
  );
}

export const useNavContext = () => useContext(navContext)

const Nav = ({ }: Props): React.ReactNode => {

  const { hide } = useNavContext()
  const [menuState, setMenuState] = useState<MenuState>(false);

  const closeMenu = () => setMenuState(false);

  const noBubblesOnlyRose = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    return null;
  }, [])

  const toggleMenu = useCallback((e: React.MouseEvent) => {
    setMenuState(s => !s)
    e.stopPropagation();
    e.preventDefault();
  }, [setMenuState, menuState])


  return (
    <section className={cx({ [nav_section]: true, [nav_open]: menuState })} onClick={closeMenu}>
      <nav>


        <div className={cx(nav_logo_section)}>
          <h1>
            <Link to='/'>
              {hide
                ? "⮜"
                : <StaticImage objectFit='contain' height={50} loading='eager' alt='KEMENÉ' src={logo} />
              }
            </Link>
          </h1>
          <ContactDetails noPhone />
        </div>

        <div>

          <div className={cx(nav_controls)}>
            <a href='tel:1800 536 363' className={cx(call_now)}>✆ 1800 KEMENE</a>
            <button
              onClick={toggleMenu}
              key='nav-open-button-nav'
            >
              {menuState ? '✕' : '☰'}
            </button>
          </div>
          <ul key='nav-menu'>


            <li><Link onClick={closeMenu} activeClassName={active_nav_link} to='/'>
              <FormattedMessage id='common.pages.home' />
            </Link></li>
            <li><Link onClick={closeMenu} activeClassName={active_nav_link} to='/services'>
              <FormattedMessage id='common.pages.services' />
            </Link></li>
            <li><Link onClick={closeMenu} activeClassName={active_nav_link} to='/case-studies'>
              <FormattedMessage id='common.pages.case-studies' />
            </Link></li>
            <li><Link onClick={closeMenu} activeClassName={active_nav_link} to='/contact-us'>
              <FormattedMessage id='common.pages.contact-us' />
            </Link></li>
            <li><Link onClick={closeMenu} activeClassName={active_nav_link} to='/appointments'>
              <FormattedMessage id='common.pages.appointments' />
            </Link></li>


            <IntlContextConsumer>
              {({ language }) => {
                if (language != 'en') return null;
                return (
                  <li className={cx(call_now, mobile_only)}>
                    <a href='tel:1800 536 363'>
                      ✆ 1800 KEMENE
                    </a>
                  </li>
                )
              }}
            </IntlContextConsumer>
            <LangSelector text onSelect={noBubblesOnlyRose} as='li' />
          </ul>



        </div>

      </nav>
    </section >
  )
}

export default Nav
