import React from 'react';
import cx from 'classnames'

import {
  section_footer,
  with_popover,
} from './styles.module.css'

import LangSelector from '../components/lang-selector'

import { usePopoverIsOpen } from '../components/popover'
import { Link, FormattedMessage } from 'gatsby-plugin-intl'

const Footer = (): React.ReactNode => {
  const anyPopoverOpen = usePopoverIsOpen();
  return (
    <section className={cx(section_footer, { [with_popover]: anyPopoverOpen })}>
      <div>
        <p><FormattedMessage id='common.footer.copyright' /></p>
        <p><FormattedMessage id='common.footer.legal' /></p>

        <ul>

          <li><Link to='/privacy-policy'>
            <FormattedMessage id='common.pages.privacy-policy' />
          </Link></li>

          <li><Link to='/terms-and-conditions'>
            <FormattedMessage id='common.pages.terms-and-conditions' />
          </Link></li>

          <li><Link to='/contact-us'>
            <FormattedMessage id='common.pages.contact-us' />
          </Link></li>

          <li><LangSelector text /></li>
        </ul>
      </div>
    </section>
  )
}

export default Footer
