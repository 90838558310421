import React from 'react'
import Helmet from 'react-helmet'

import { useStaticQuery, graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl'



const FormattedMessageValues: Record<string, any> = {
  strong: (str: string) => str,
  span: (str: string) => str,
}

interface Props {
  // children: React.ReactNode
}

const SEO = ({ }: Props) => {
  const intl = useIntl()
  const description = intl.formatMessage({ id: 'index.hero' }, FormattedMessageValues) as string

  const { site, ogImageDefault } = useStaticQuery(graphql`
query {
  site {
    siteMetadata {
      title
      siteUrl
    }
  }
  ogImageDefault: file(relativePath: {eq: "kemene_consulting_banner.jpg"}) {
    childImageSharp {
        gatsbyImageData(height: 630, width: 1200, layout: CONSTRAINED)
    }
  }
}
`)


  const { title, siteUrl } = site.siteMetadata;

  const heroImage = `${siteUrl}${ogImageDefault.childImageSharp.gatsbyImageData}`

  return (
    <Helmet htmlAttributes={{ lang: intl.locale }}>
      <meta charSet='utf-8' />

      <title>{title}</title>
      <meta name='title' content={title} />

      <meta name='description' content={description} />
      <meta name='image' property='og:image' content={heroImage} />
      <meta name='image' property='twitter:image' content={heroImage} />


      <meta property='og:type' content='website' />
      <meta property='og:url' content={siteUrl} />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      <meta property='og:image' content={heroImage} />

      <meta property='twitter:card' content={heroImage} />
      <meta property='twitter:url' content={siteUrl} />
      <meta property='twitter:title' content={title} />
      <meta property='twitter:description' content={description} />
      <meta property='twitter:image' content={heroImage} />

    </Helmet>
  )
}

export default SEO;
